<adapt-dialog>
    <div adapt-dialog-title>{{dialogName}}</div>
    <div adapt-dialog-content>
        <div class="form-group">
            <label>Improve on</label>
            <div [froalaView]="workflowRating.improveOn"></div>
        </div>

        <div class="form-group">
            <label>Liked</label>
            <div [froalaView]="workflowRating.liked"></div>
        </div>

        <div class="form-group">
            <label>Status</label>
            <dx-select-box [items]="[PathwayRatingStatus.awaiting_review, PathwayRatingStatus.actioned]"
                           [(value)]="workflowRating.status">
            </dx-select-box>
        </div>

        <div class="form-group">
            <label>Comment</label>
            <adapt-html-editor [(contents)]="workflowRating.comment"></adapt-html-editor>
        </div>
    </div>
    <div adapt-dialog-footer
         adaptBlockingButtonGroup
         class="ms-auto">
        <button adaptButton="save"
                (click)="save()"></button>
        <button adaptButton="cancel"
                (click)="resolve(workflowRating)"></button>
    </div>
</adapt-dialog>
