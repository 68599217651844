<div class="row">
    <div class="col-md-6 col-xl-4">
        <h4>Organisation To Archive</h4>
        <adapt-select-organisation (organisationChange)="organisation = $event"
                                   [filter]="orgFilter"></adapt-select-organisation>

        <div role="alert"
             class="mt-3 alert alert-danger">
            Impact of archiving an organisation:
            <ul>
                <li>Organisation account status will be set to inactive</li>
                <li>All roles & users will have their edit privileges removed</li>
                <ul>
                    <li>Users can still log in</li>
                    <li>Users can read all data they have currently entered</li>
                    <li>Users can not add/remove people from the organisation</li>
                </ul>
                <li>All coach connections to the organisaton will be ended</li>
            </ul>
        </div>

        <button adaptButtonIcon
                [adaptBlockingClick]="archive">Archive Organisation</button>

        <div role="alert"
             class="alert alert-danger"
             *ngIf="!!error">{{error}}</div>
    </div>
</div>
