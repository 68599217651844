<adapt-dialog [maxWidth]="500">
    <h3 adapt-dialog-title>Start subscription</h3>

    <div adapt-dialog-content>
        <div class="form-group">
            <label for="date">Date</label>
            <dx-date-box id="date"
                         type="date"
                         [value]="date"
                         [min]="minDate"
                         [max]="maxDate"
                         (valueChange)="date =$any($event)">
            </dx-date-box>
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="ok"
                                   [saveIsDisabled]="!date"
                                   (saveClick)="save()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
