<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>{{isNew ? 'Add' : 'Edit'}} Coach Details</h3>

    <ng-container adapt-dialog-content>
        <form role="form">
            <div class="form-group">
                <label for="selectPerson">Person</label>
                <adapt-select-person *ngIf="isNew; else NotNewCoachTemplate"
                                     id="selectPerson"
                                     [(person)]="practitioner.person"></adapt-select-person>
                <ng-template #NotNewCoachTemplate>
                    <adapt-link-person class="d-block"
                                       [person]="practitioner.person"></adapt-link-person>
                </ng-template>
            </div>

            <div class="form-group">
                <label for="editStartDate">Start Date</label>
                <dx-date-box id="editStartDate"
                             type="date"
                             [value]="practitioner.startDate"
                             (valueChange)="practitioner.startDate = $any($event)"
                             [width]="200"></dx-date-box>
            </div>

            <div class="form-group">
                <label for="editEndDate">End Date</label>
                <dx-date-box id="editEndDate"
                             type="date"
                             [showClearButton]="true"
                             [min]="practitioner.startDate"
                             [value]="practitioner.endDate"
                             (valueChange)="practitioner.endDate = $any($event)"
                             [width]="200"></dx-date-box>
            </div>

            <div class="form-group">
                <label for="editNotes">Notes</label>
                <adapt-html-editor id="editNotes"
                                   [(contents)]="practitioner.notes"></adapt-html-editor>
            </div>

            <div class="form-group">
                <dx-check-box id="editSubscribing"
                              text="Subscribing"
                              [value]="practitioner.isSubscribing"
                              (valueChange)="practitioner.isSubscribing = $any($event)"></dx-check-box>
            </div>

            <div class="form-group">
                <label for="editDocumentationLevel">Helpjuice Access</label>
                <dx-select-box *ngIf="practitioner.person.preferences"
                               [items]="allLevels"
                               [width]="350"
                               [(value)]="practitioner.person.preferences.documentationSubscriptionLevel"
                               fieldTemplate="field">
                    <div *dxTemplate="let data of 'item'">
                        {{documentationSubscriptionLevelLabeller.label(data)}}
                    </div>
                    <div *dxTemplate="let data of 'field'"
                         class="m-2">
                        {{documentationSubscriptionLevelLabeller.label(data)}}
                        <dx-text-box class="d-none"
                                     value="hidden element required by dxTemplate"
                                     [readOnly]="true"></dx-text-box>
                    </div>
                </dx-select-box>
            </div>

            <div class="form-group">
                <label for="editPartner">Coach Type</label>
                <dx-select-box [items]="allTypes"
                               [width]="350"
                               displayExpr="name"
                               valueExpr="type"
                               [(value)]="practitioner.coachType">
                </dx-select-box>
            </div>

            <label class="mb-2">Accreditations</label>
            <div class="form-group">
                <dx-check-box text="Accredited RB Coach"
                              [value]="practitioner.isAccreditedRBCoach"
                              (valueChange)="practitioner.isAccreditedRBCoach = $any($event)"></dx-check-box>
            </div>

            <div class="form-group">
                <dx-check-box text="Accredited Owner Mentor"
                              [value]="practitioner.isAccreditedOwnerMentor"
                              (valueChange)="practitioner.isAccreditedOwnerMentor = $any($event)"></dx-check-box>
            </div>

            <div class="form-group">
                <label for="editPartner">Strategic Partner</label>
                <adapt-select-partner id="editPartner"
                                      [(partner)]="practitioner.partner">
                </adapt-select-partner>
            </div>
        </form>

    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
