<div adaptToolbarContent>
    <span class="dx-datagrid-export-button">
        <button adaptButtonIcon="dx-icon dx-icon-xlsxfile"
                adaptTooltip="Export all data"
                (click)="exportAllData()"></button>
    </span>
    <button adaptButtonIcon="dx-icon dx-icon-column-chooser"
            adaptTooltip="Column chooser"
            (click)="showColumnChooser()"></button>
    <button adaptButtonIcon="dx-icon dx-icon-revert"
            adaptTooltip="Reset columns"
            (click)="resetColumns()"></button>
</div>

<dx-data-grid [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              [dataSource]="data"
              (onInitialized)="onGridInitialized($event)">
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-paging [pageSize]="25">
    </dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10,25,50,100,250]">
    </dxo-pager>
    <dxo-group-panel [visible]="true">
    </dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true">
    </dxo-grouping>
    <dxo-column-chooser [enabled]="false">
    </dxo-column-chooser>

    <!-- Columns -->
    <dxi-column caption="Id"
                dataField="organisation.organisationId"
                dataType="number"
                [allowHeaderFiltering]="false">
    </dxi-column>

    <dxi-column caption="Name"
                dataField="organisation.name"
                dataType="string"
                sortOrder="asc"
                cellTemplate="nameCellTemplate"
                [allowHeaderFiltering]="false">
    </dxi-column>

    <dxi-column caption="Coach"
                [allowFiltering]="true"
                [allowHeaderFiltering]="false"
                dataField="coach"
                dataType="string"
                cellTemplate="coachCellTemplate"
                [calculateCellValue]="calculateCoachCellValue">
    </dxi-column>

    <dxi-column caption="embedADAPT"
                dataField="organisation.urlIdentifier"
                dataType="string"
                [allowHeaderFiltering]="false"
                cellTemplate="embedUrlCellTemplate">
    </dxi-column>

    <dxi-column caption="Status"
                dataField="account.status"
                dataType="integer"
                [groupIndex]="0">
    </dxi-column>

    <dxi-column caption="Sub Status"
                dataField="account.subStatus"
                dataType="integer"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Type"
                dataField="account.organisationType"
                dataType="string"
                [filterValues]="['Standard']">
    </dxi-column>

    <dxi-column caption="Pricing Model"
                dataField="account.pricingModel"
                dataType="string"
                [calculateCellValue]="calculatePricingModelCellValue">
    </dxi-column>

    <dxi-column caption="Subscription Commenced"
                dataField="account.subscriptionCommencedDate"
                dataType="date"
                [format]="dateFormat"
                [allowSorting]="true"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Next Charge Date"
                dataField="account.nextSubscriptionInvoiceDate"
                dataType="date"
                [format]="dateFormat"
                [allowSorting]="true"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Monthly Fee (Fixed Only)"
                dataField="account.monthlyFeeDollars"
                dataType="number"
                [allowSorting]="true"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Billing Period"
                dataField="account.billingPeriod"
                dataType="number"
                [allowSorting]="true"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Payment method"
                dataField="account.paymentMethod"
                dataType="string"
                [allowSorting]="true"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Referrer"
                dataField="referrer"
                dataType="string"
                groupCellTemplate="referrerGroupCellTemplate"
                [visible]="true">
    </dxi-column>

    <dxi-column caption="Organisation Details"
                dataField="organisation.organisationDetails.value"
                dataType="string"
                cellTemplate="richTextTemplate"
                [calculateCellValue]="calculateOrganisationDetailCellValue"
                [visible]="false">
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let item of 'nameCellTemplate'">
        <adapt-link-organisation [organisation]="item.data.organisation">
        </adapt-link-organisation>
    </div>
    <div *dxTemplate="let item of 'coachCellTemplate'">
        <div *ngFor="let coach of item.data.coaches">
            <adapt-link-coach [coach]="coach"></adapt-link-coach>
        </div>
    </div>
    <div *dxTemplate="let item of 'embedUrlCellTemplate'">
        <a target="_blank"
           [href]="getEmbedLinkForOrganisation(item.data.organisation)">{{item.value}}</a>
    </div>
    <div *dxTemplate="let data of 'richTextTemplate'">
        <div [froalaView]="data.value"></div>
    </div>
    <div *dxTemplate="let item of 'referrerGroupCellTemplate'">
        Referrer: {{item.value ?? "None"}}
    </div>
</dx-data-grid>
