<div class="card">
    <div class="card-body">
        <ng-container *ngIf="pricingModel; else noPricingModelSetTemplate">
            <div *ngIf="pricingModel.monthlyFeeDollars > 0 && account">Fixed {{account.extensions.billingPeriodLabel()}} Fee:
                {{account.extensions.subscriptionCost | currency:account.currency?.shortName}}</div>

            <ng-container *ngIf="fullUsers.length > 0">
                <div><strong>{{UserTypeExtensions.singularLabel(UserType.Leader)}}</strong></div>
                <ng-container *ngTemplateOutlet="userTemplate; context: {users: fullUsers}"></ng-container>
            </ng-container>

            <ng-container *ngIf="collaboratorUsers.length > 0">
                <div class="mt-2"><strong>{{UserTypeExtensions.singularLabel(UserType.Collaborator)}}</strong></div>
                <ng-container *ngTemplateOutlet="userTemplate; context: {users: collaboratorUsers}"></ng-container>
            </ng-container>

            <ng-container *ngIf="viewerUsers.length > 0">
                <div class="mt-2"><strong>{{UserTypeExtensions.singularLabel(UserType.Viewer)}}</strong></div>
                <ng-container *ngTemplateOutlet="userTemplate; context: {users: viewerUsers}"></ng-container>
            </ng-container>
        </ng-container>

        <ng-template #userTemplate
                     let-users="users">
            <div *ngFor="let user of users"
                 class="ms-2">
                {{user.minUsers}} - {{user.maxUsers ?? ''}}: ${{user.monthlyFeeDollars}}
            </div>
        </ng-template>

        <ng-template #noPricingModelSetTemplate>
            No pricing model set.
        </ng-template>
    </div>
</div>
