import { Component, Inject } from "@angular/core";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-start-subscription-dialog",
    templateUrl: "./start-subscription-dialog.component.html",
})
export class StartSubscriptionDialogComponent extends BaseDialogComponent<Date> {
    protected readonly autoResolveData = this.date;

    public readonly dialogName = "StartSubscription";
    public minDate = new Date();
    public maxDate = new Date(this.minDate.getFullYear()+1, this.minDate.getMonth(), this.minDate.getDate());

    constructor(@Inject(ADAPT_DIALOG_DATA) public date: Date,
    ) {
        super();
    }

    public save() {
        this.resolve(this.date);
    }
}
