import moment from "moment/moment";
import { Account, OrganisationType, PaymentMethod, SubscriptionStatus, SubscriptionSubStatus } from "./account";

// duplicated in subscription.cy.ts
export enum BillingPeriod {
    Monthly = 1,
    Quarterly = 3,
    Annually = 12
}

export const BillingPeriodButtons: any[] = [{
    billingPeriod: BillingPeriod.Monthly,
    text: "Monthly",
}, {
    billingPeriod: BillingPeriod.Annually,
    text: "Annually",
}];

export class AccountExtensions {
    public constructor(private account: Account) { }

    public get isActive() {
        return this.account.status === SubscriptionStatus.Active;
    }

    public get isTrial() {
        return this.account.status === SubscriptionStatus.Trial;
    }

    public get isZeroDayTrial() {
        // account would have been archived by webjob overnight and becomes inactive
        // - just need to verify this is a 0 day trial account - provided the subscription is not active
        // - after subscribing, nextSubscriptionInvoiceDate will be moved forward and this won't be true anymore
        return this.account.pricingModel?.trialLength === 0 && // will be false if no pricingModel
            this.account.status !== SubscriptionStatus.Active &&
            !!this.account.nextSubscriptionInvoiceDate &&
            // Date1 === Date2 returns false even if Date1.getTime() === Date2.getTime()
            this.account.subscriptionCommencedDate?.getTime() === this.account.nextSubscriptionInvoiceDate.getTime(); // this is already set by org builder for alto only
    }

    public get isExpiredTrial() {
        const expiredTrialSubStatuses = [SubscriptionSubStatus.TrialEnded, SubscriptionSubStatus.TrialExtendedEnded];
        return expiredTrialSubStatuses.includes(this.account.subStatus) && this.isInactive;
    }

    public get isPendingCancellation() {
        return this.account.status === SubscriptionStatus.PendingInactive;
    }

    public get isInactive() {
        return this.account.status === SubscriptionStatus.Inactive;
    }

    public get isBilledUsingCreditCard() {
        return this.account.paymentMethod === PaymentMethod.CreditCard;
    }

    public get isCreditCardSet() {
        return !!this.account.paymentProcessorCustomerId;
    }

    public get isStandardOrganisation() {
        return this.account.organisationType === OrganisationType.Standard;
    }

    public get isUsingPricingModel() {
        return !!this.account.pricingModelId;
    }

    /**
      * Get the fixed annual cost of this subscription.
      * This does NOT take into account any per-user costs that may apply.
    */
    public get annualSubscriptionCost() {
        return this.monthlySubscriptionCost * BillingPeriod.Annually;
    }

    /**
     * Get the fixed monthly cost of this subscription.
     * This does NOT take into account any per-user costs that may apply.
     */
    public get monthlySubscriptionCost() {
        if (this.account.pricingModel) {
            if (this.account.billingPeriod === BillingPeriod.Annually) {
                return this.account.pricingModel.monthlyFeeDollars * (1 - (this.account.pricingModel.annualDiscountPercentage / 100));
            }

            return this.account.pricingModel.monthlyFeeDollars;
        }

        return this.account.monthlyFeeDollars;
    }

    public get isFree() {
        if (this.account.pricingModel) {
            return this.account.pricingModel.extensions.isFree;
        }

        return this.account.monthlyFeeDollars === 0;
    }

    public get billingMethodAndPeriodLabel() {
        const billingMethod = this.isBilledUsingCreditCard
            ? "credit card payments"
            : "invoices";
        switch (this.account.billingPeriod) {
            case BillingPeriod.Monthly:
                return `${this.billingPeriodLabel()} ${billingMethod}`;
            case BillingPeriod.Quarterly:
                return `${this.billingPeriodLabel()} ${billingMethod}`;
            case BillingPeriod.Annually:
                return `${this.billingPeriodLabel(true)} ${billingMethod}`;
            default:
                return `${billingMethod} every ${this.account.billingPeriod} months`;
        }
    }

    public billingPeriodLabel(annualAsAdverb = false) {
        switch (this.account.billingPeriod) {
            case BillingPeriod.Monthly:
                return "monthly";
            case BillingPeriod.Quarterly:
                return "quarterly";
            case BillingPeriod.Annually:
                return annualAsAdverb ? "annually" : "annual";
            default:
                throw new Error(`invalid billing period: ${this.account.billingPeriod}`);
        }
    }

    /**
     * Get subscription cost
     * takes into account the billing period.
     * Only supports Monthly & Annually billing periods
     */
    public get subscriptionCost() {
        switch (this.account.billingPeriod) {
            case BillingPeriod.Monthly:
                return this.account.extensions.monthlySubscriptionCost;
            case BillingPeriod.Annually:
                return this.account.extensions.annualSubscriptionCost;
            default:
                throw new Error(`We don't currently support calculating cost for billing period: ${this.account.billingPeriod}`);
        }
    }

    public get latestInvoice() {
        if (!this.account.invoices || !this.account.invoices.length) {
            return undefined;
        }

        let latestInvoice = this.account.invoices[0];
        this.account.invoices.forEach((invoice) => {
            if (invoice.date > latestInvoice.date) {
                latestInvoice = invoice;
            }
        });

        return latestInvoice;
    }

    /**
     * Adds tax to cost. Unless account is taxExempt.
     */
    public getTotalCost(cost: number) {
        const taxRate = this.account.taxExempt ? 1 : 1.1;
        return cost * taxRate;
    }

    public canResumeSubscriptionWithoutCharge(originalStatus?: SubscriptionStatus) {
        return (originalStatus ?? this.account.status) === SubscriptionStatus.PendingInactive
            && moment().isBefore(this.account.nextSubscriptionInvoiceDate);
    }
}
