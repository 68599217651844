import { Component, Input, OnChanges } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { PricingModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { PricingModelUser } from "@common/ADAPT.Common.Model/embed/pricing-model-user";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";

@Component({
    selector: "adapt-display-pricing-model",
    templateUrl: "./display-pricing-model.component.html",
})
export class DisplayPricingModelComponent implements OnChanges {
    @Input() public pricingModel?: PricingModel;
    @Input() public account?: Account;

    public PricingModelUser = PricingModelUser;
    public fullUsers: PricingModelUser[] = [];
    public collaboratorUsers: PricingModelUser[] = [];
    public viewerUsers: PricingModelUser[] = [];
    public UserType = UserType;
    public UserTypeExtensions = UserTypeExtensions;

    public ngOnChanges() {
        if (this.pricingModel) {
            this.fullUsers = this.pricingModel!.pricingModelUsers
                .filter((i) => i.userType === UserType.Leader)
                .sort((a, b) => a.minUsers - b.minUsers);
            this.collaboratorUsers = this.pricingModel!.pricingModelUsers
                .filter((i) => i.userType === UserType.Collaborator)
                .sort((a, b) => a.minUsers - b.minUsers);
            this.viewerUsers = this.pricingModel!.pricingModelUsers
                .filter((i) => i.userType === UserType.Viewer)
                .sort((a, b) => a.minUsers - b.minUsers);
        }
    }
}
