import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { OrganisationType } from "@common/ADAPT.Common.Model/account/account";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import { defer } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { SelectOrganisationComponent } from "../select-organisation/select-organisation.component";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-seed-training-organisations",
    templateUrl: "./seed-training-organisations.component.html",
})
export class SeedTrainingOrganisationsComponent extends BaseRoutedComponent implements OnInit {
    public readonly UrlIdentifierPrefix = "adapttrain";

    @ViewChild(SelectOrganisationComponent)
    public selectOrganisationComponent?: SelectOrganisationComponent;

    public result?: string;
    public error?: string;
    public urlIdentifier: string = `${this.UrlIdentifierPrefix}1`;
    public masterOrganisation?: Organisation;
    private allOrganisations: Organisation[] = [];

    constructor(
        private stakeholderServices: StakeholderServicesService,
        public orgService: OrganisationsService,
        public dialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public filter = (i: Organisation) => i.getAccount()?.organisationType === OrganisationType.Sandbox;

    public async ngOnInit() {
        this.notifyActivated();
        await this.updateAllOrganisations(true);
        this.findNextUnusedIdentifier();
    }

    @Autobind
    public seedTrainingOrgs() {
        this.result = undefined;
        this.error = undefined;

        return defer(() => this.stakeholderServices.seedTrainingOrgs({
            urlIdentifier: this.urlIdentifier,
            masterOrgIdentifier: this.masterOrganisation?.urlIdentifier,
        }))
            .pipe(
                tap((result) => this.result = result.data),
                tap(() => this.selectOrganisationComponent!.reload()),
                switchMap(() => this.updateAllOrganisations(true)),
                switchMap(() => this.dialogService.showMessageDialog("Seed Training Org Completed", "Seed Training Org Completed")),
                tap(() => this.findNextUnusedIdentifier()),
                catchError((e) => this.error = ErrorHandlingUtilities.getHttpResponseMessage(e)),
            );
    }

    public onUrlIdentifierChanged(newIdentifier: string) {
        if (this.allOrganisations.find((i) => i.urlIdentifier === newIdentifier)) {
            this.error = "Training organisation cannot be built as there is an existing organisation with that URL identifier";
        } else {
            this.error = undefined;
        }
    }

    private async updateAllOrganisations(forceRemote = false) {
        this.allOrganisations = await this.orgService.getOrganisationsWithAccounts(forceRemote);
    }

    private findNextUnusedIdentifier() {
        let index = 1;
        while (this.allOrganisations.find((i) => i.urlIdentifier === this.urlIdentifier)) {
            this.urlIdentifier = `${this.UrlIdentifierPrefix}${index++}`;
        }
    }
}
