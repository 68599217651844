import { Component, ElementRef, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Account, SubscriptionStatus } from "@common/ADAPT.Common.Model/account/account";
import { PricingModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { OrganisationDetailNames } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DateFormats } from "@common/ux/date-formats";
import { NimbusDataService } from "app/services/nimbus-data.service";
import { InitializedEvent } from "devextreme/ui/data_grid";
import { forkJoin, lastValueFrom } from "rxjs";
import { OrganisationsService } from "../organisations.service";

interface IOrganisationData {
    account: Account;
    organisation: Organisation;
    referrer?: string;
    coaches: (Practitioner | undefined)[];
}

@Component({
    selector: "adapt-organisations-list",
    templateUrl: "./organisations-list.component.html",
})
export class OrganisationsListComponent extends BaseRoutedComponent implements OnInit, OnChanges {
    @Input() public filterForCoachId?: number;

    public dateFormat = DateFormats.globalize.short;

    public dxGridWrapperHelper: DxGridWrapperHelper;

    public data: IOrganisationData[] = [];

    constructor(
        private nimbusDataService: NimbusDataService,
        private organisationsService: OrganisationsService,
        elementRef: ElementRef,
        injector: Injector,
    ) {
        super(injector);

        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-organisations-list", jQuery(elementRef.nativeElement));
        this.dxGridWrapperHelper.saveGridState("adapt-organisations-list");
        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());
    }

    public async ngOnInit() {
        await this.updateData();
        this.notifyActivated();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.filterForCoachId && changes.filterForCoachId.previousValue !== changes.filterForCoachId.currentValue) {
            await this.updateData();
        }
    }

    public onGridInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.initialiseGrid(e);
    }

    public getEmbedLinkForOrganisation(organisation: Organisation) {
        return this.organisationsService.getEmbedLinkForOrganisation(organisation);
    }

    public calculateCoachCellValue(rowData: IOrganisationData) {
        return rowData.coaches
            .map((coach) => coach?.person?.fullName)
            .join(",");
    }

    /**
     * Functionality is duplicated in AccountExtensions.cs PricingModelDescription
     */
    public calculatePricingModelCellValue(rowData: IOrganisationData) {
        if (rowData.account.status === SubscriptionStatus.Active) {
            if (rowData.account.pricingModel) {
                return PricingModel.PerUserPricingModelDescription + " - " + rowData.account.pricingModel.name;
            }

            return PricingModel.FixedPricingModelDescription;
        }

        return PricingModel.NotApplicablePricingModelDescription;
    }

    public calculateOrganisationDetailCellValue(rowData: IOrganisationData) {
        return rowData.organisation.organisationDetails
            .sort(SortUtilities.getSortByFieldFunction("name"))
            .map((detail) => `${detail.name}: ${detail.value}`)
            .join("<br>");
    }

    @Autobind
    public showColumnChooser() {
        this.dxGridWrapperHelper.callGrid((grid) => grid.component.showColumnChooser());
    }

    @Autobind
    public exportAllData() {
        this.dxGridWrapperHelper.callGrid((grid) => DxUtilities.exportGridToExcel("Organisations", grid.component));
    }

    public resetColumns() {
        this.dxGridWrapperHelper.callGrid((grid) => grid.resetState());
    }

    public async updateData() {
        // we need to fetch all this to avoid making lots of individual requests
        await lastValueFrom(forkJoin([
            this.nimbusDataService.getAccounts(),
            this.organisationsService.getPricingModels(),
        ]));

        const practitioners = await lastValueFrom(this.nimbusDataService.getPractitioners());
        const organisations = this.filterForCoachId
            ? await lastValueFrom(this.organisationsService.getOrganisationsForCoachId(this.filterForCoachId))
            : await lastValueFrom(this.organisationsService.getOrganisations());
        this.data = organisations.map((organisation) => this.extractData(organisation, practitioners));
    }

    private extractData(organisation: Organisation, practitioners: Practitioner[]) {
        const coachPersonIds = organisation.connections
            .filter((i) => i.isActive() && i.connectionType === ConnectionType.Coach)
            .map((i) => i.personId);

        return {
            organisation,
            referrer: organisation.getDetailValue(OrganisationDetailNames.Referrer),
            account: organisation.getAccount(),
            coaches: practitioners
                .filter((i) => i.isActive())
                .filter((i) => coachPersonIds.some((j) => j === i.personId)),
        } as IOrganisationData;
    }
}
