import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxNumberBoxModule, DxRadioGroupModule, DxSelectBoxModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";
import { ArchiveOrganisationPageComponent } from "./archive-organisation-page/archive-organisation-page.component";
import { archiveOrganisationPageRoute } from "./archive-organisation-page/archive-organisation-page.route";
import { ArchiveValueStreamPageComponent } from "./archive-value-stream-page/archive-value-stream-page.component";
import { archiveValueStreamPageRoute } from "./archive-value-stream-page/archive-value-stream-page.route";
import { CacheStatusPageComponent } from "./cache-status-page/cache-status-page.component";
import { cacheStatusPageRoute } from "./cache-status-page/cache-status-page.route";
import { CheckMyMathsComponent } from "./check-my-maths/check-my-maths.component";
import { ConfigureOrganisationFeaturesComponent } from "./configure-organisation-features/configure-organisation-features.component";
import { configureOrganisationFeaturesRoute } from "./configure-organisation-features/configure-organisation-features.route";
import { CopyMeetingAgendaPageComponent } from "./copy-meeting-agenda-template-page/copy-meeting-agenda-template-page.component";
import { copyMeetingAgendaTemplatePageRoute } from "./copy-meeting-agenda-template-page/copy-meeting-agenda-template-page.route";
import { CopySystemPageComponent } from "./copy-system-page/copy-system-page.component";
import { copySystemPageRoute } from "./copy-system-page/copy-system-page.route";
import { CopyTeamPageComponent } from "./copy-team-page/copy-team-page.component";
import { copyTeamPageRoute } from "./copy-team-page/copy-team-page.route";
import { DeleteOrganisationPageComponent } from "./delete-organisation-page/delete-organisation-page.component";
import { deleteOrganisationPageRoute } from "./delete-organisation-page/delete-organisation-page.route";
import { DeleteOrphanPeopleComponent } from "./delete-orphan-people-page/delete-orphan-people-page.component";
import { deleteOrphanPeoplePageRoute } from "./delete-orphan-people-page/delete-orphan-people-page.route";
import { DeleteOrphanUsersComponent } from "./delete-orphan-users-page/delete-orphan-users-page.component";
import { deleteOrphanUsersPageRoute } from "./delete-orphan-users-page/delete-orphan-users-page.route";
import { DeleteSurveyPageComponent } from "./delete-survey-page/delete-survey-page.component";
import { deleteSurveyPageRoute } from "./delete-survey-page/delete-survey-page.route";
import { DeleteUserPageComponent } from "./delete-user-page/delete-user-page.component";
import { deleteUserPageRoute } from "./delete-user-page/delete-user-page.route";
import { DeleteValueStreamPageComponent } from "./delete-value-stream-page/delete-value-stream-page.component";
import { deleteValueStreamPageRoute } from "./delete-value-stream-page/delete-value-stream-page.route";
import { ListPrivilegedUsersPageComponent } from "./list-privileged-users-page/list-privileged-users-page.component";
import { listPrivilegedUsersRoute } from "./list-privileged-users-page/list-privileged-users-page.route";
import { MergeUsersPageComponent } from "./merge-users-page/merge-users-page.component";
import { mergeUsersPageRoute } from "./merge-users-page/merge-users-page.route";
import { MoveBoardPageComponent } from "./move-board-page/move-board-page.component";
import { moveBoardPageRoute } from "./move-board-page/move-board-page.route";
import { RerankKanbanItemsComponent } from "./rerank-kanban-items/rerank-kanban-items.component";
import { rerankKanbanItemsRoute } from "./rerank-kanban-items/rerank-kanban-items.route";
import { SeedDatabasePageComponent } from "./seed-database-page/seed-database-page.component";
import { seedDatabasePageRoute } from "./seed-database-page/seed-database-page.route";
import { SeedTrainingOrganisationsComponent } from "./seed-training-organisations/seed-training-organisations.component";
import { seedTrainingOrganisationRoute } from "./seed-training-organisations/seed-training-organisations.route";
import { SelectBoardComponent } from "./select-board/select-board.component";
import { SelectMeetingAgendaTemplateComponent } from "./select-meeting-agenda-template/select-meeting-agenda-template.component";
import { SelectOrganisationComponent } from "./select-organisation/select-organisation.component";
import { SelectPersonComponent } from "./select-person/select-person.component";
import { SelectRoleComponent } from "./select-role/select-role.component";
import { SelectSurveyComponent } from "./select-survey/select-survey.component";
import { SelectSystemComponent } from "./select-system/select-system.component";
import { SelectTeamComponent } from "./select-team/select-team.component";
import { SelectValueStreamComponent } from "./select-value-stream/select-value-stream.component";
import { StakeholderServicesService } from "./stakeholder-services.service";
import { TriggerEventSchedulerComponent } from "./trigger-event-scheduler/trigger-event-scheduler.component";
import { triggerEventSchedulerRoute } from "./trigger-event-scheduler/trigger-event-scheduler.route";
import { TriggerStewardshipActivityComponent } from "./trigger-stewardship-activity/trigger-stewardship-activity.component";
import { triggerStewardshipActivityRoute } from "./trigger-stewardship-activity/trigger-stewardship-activity.route";
import { TriggerSurveySchedulerComponent } from "./trigger-survey-scheduler/trigger-survey-scheduler.component";
import { triggerSurveySchedulerRoute } from "./trigger-survey-scheduler/trigger-survey-scheduler.route";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdaptButtonModule,
        AdaptFeatureModule,
        AdaptShellModule,
        DxDataGridModule,
        DxCheckBoxModule,
        AdaptLoadingSpinnerModule,
        DxNumberBoxModule,
        DxValidatorModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxRadioGroupModule,
        AdaptDateModule,
    ],
    declarations: [
        SelectValueStreamComponent,
        SelectOrganisationComponent,
        SelectMeetingAgendaTemplateComponent,
        ArchiveValueStreamPageComponent,
        DeleteValueStreamPageComponent,
        ConfigureOrganisationFeaturesComponent,
        SeedTrainingOrganisationsComponent,
        RerankKanbanItemsComponent,
        TriggerSurveySchedulerComponent,
        ListPrivilegedUsersPageComponent,
        MergeUsersPageComponent,
        CheckMyMathsComponent,
        DeleteUserPageComponent,
        DeleteOrphanPeopleComponent,
        DeleteOrphanUsersComponent,
        SelectTeamComponent,
        MoveBoardPageComponent,
        SelectBoardComponent,
        DeleteOrganisationPageComponent,
        CacheStatusPageComponent,
        SeedDatabasePageComponent,
        ArchiveOrganisationPageComponent,
        TriggerStewardshipActivityComponent,
        DeleteSurveyPageComponent,
        SelectSurveyComponent,
        CopySystemPageComponent,
        SelectSystemComponent,
        SelectRoleComponent,
        CopyTeamPageComponent,
        SelectPersonComponent,
        CopyMeetingAgendaPageComponent,
        TriggerEventSchedulerComponent,
    ],
    providers: [
        StakeholderServicesService,
    ],
    exports: [
        SelectOrganisationComponent,
        SelectPersonComponent,
    ],
})
export class AdaptToolsModule {
    public static readonly Routes = [
        ...archiveOrganisationPageRoute.routes,
        ...archiveValueStreamPageRoute.routes,
        ...cacheStatusPageRoute.routes,
        ...configureOrganisationFeaturesRoute.routes,
        ...copySystemPageRoute.routes,
        ...copyTeamPageRoute.routes,
        ...deleteOrganisationPageRoute.routes,
        ...deleteOrphanPeoplePageRoute.routes,
        ...deleteOrphanUsersPageRoute.routes,
        ...deleteSurveyPageRoute.routes,
        ...deleteUserPageRoute.routes,
        ...deleteValueStreamPageRoute.routes,
        ...listPrivilegedUsersRoute.routes,
        ...mergeUsersPageRoute.routes,
        ...moveBoardPageRoute.routes,
        ...rerankKanbanItemsRoute.routes,
        ...seedDatabasePageRoute.routes,
        ...seedTrainingOrganisationRoute.routes,
        ...triggerStewardshipActivityRoute.routes,
        ...triggerSurveySchedulerRoute.routes,
        ...triggerEventSchedulerRoute.routes,
        ...copyMeetingAgendaTemplatePageRoute.routes,
    ];
}
