import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Account, PaymentMethod, SubscriptionStatus, SubscriptionSubStatus } from "@common/ADAPT.Common.Model/account/account";
import { Currency } from "@common/ADAPT.Common.Model/embed/currency";
import { PricingModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ICardPartialDetails } from "@common/payment-processing/card-partial-details.interface";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { ItemClickEvent } from "devextreme/ui/button_group";
import moment from "moment";
import { catchError, lastValueFrom, Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { StakeholderServicesService } from "../../tools/stakeholder-services.service";
import { OrganisationsService } from "../organisations.service";
import { StartSubscriptionDialogComponent } from "../start-subscription-dialog/start-subscription-dialog.component";

interface ISubscriptionStatus {
    status: SubscriptionStatus;
    icon?: string;
    text: string;
    validSubStatuses: SubscriptionSubStatus[];
}

@Component({
    selector: "adapt-account-details",
    templateUrl: "./account-details.component.html",
})
export class AccountDetailsComponent implements OnChanges {
    @Input() public organisation?: Organisation;

    public readonly statuses: ISubscriptionStatus[] = [];

    public billingPeriods: { id: number, name: string }[] = [
        {
            id: 1,
            name: "Monthly",
        },
        {
            id: 12,
            name: "Annually",
        },
    ];

    public pricingModelTypes: string[] = [
        PricingModel.FixedPricingModelDescription,
        PricingModel.PerUserPricingModelDescription,
    ];

    public paymentMethods: { id: PaymentMethod, name: string }[] = [
        {
            id: PaymentMethod.Invoice,
            name: "Invoice",
        },
        {
            id: PaymentMethod.CreditCard,
            name: "Credit Card",
        },
    ];

    public currencies$: Observable<Currency[]>;
    public pricingModels: PricingModel[] = [];

    public account?: Account;

    public editBillingDetailsMode = false;
    public editPaymentMode = false;
    public billingCommencing = false;

    public pricingModelType = PricingModel.PerUserPricingModelDescription;

    public selectedItemStatus: SubscriptionStatus[] = [];
    public selectedItemStatusObject?: ISubscriptionStatus;
    public errorMessage?: string;

    public cardDetails?: ICardPartialDetails;
    public cardDetailsLoading = true;

    public constructor(
        private organisationsService: OrganisationsService,
        private paymentProcessingService: PaymentProcessingService,
        private dialogService: AdaptCommonDialogService,
        private stakeholderServicesService: StakeholderServicesService,
    ) {
        this.currencies$ = this.organisationsService.getCurrencies();

        if (this.isAlto) {
            this.statuses.push({
                status: SubscriptionStatus.Trial,
                icon: "fal fa-magnifying-glass",
                text: "Trial",
                validSubStatuses: [
                    SubscriptionSubStatus.None,
                    SubscriptionSubStatus.TrialExtended,
                ],
            });
        } else {
            this.statuses.push({
                status: SubscriptionStatus.Potential,
                icon: "fal fa-question",
                text: "Potential",
                validSubStatuses: [SubscriptionSubStatus.None],
            });

            this.billingPeriods.splice(1, 0, {
                id: 3,
                name: "Quarterly",
            });
        }

        this.statuses.push({
            status: SubscriptionStatus.Active,
            icon: "fal fa-check",
            text: "Active",
            validSubStatuses: [
                SubscriptionSubStatus.None,
                SubscriptionSubStatus.TrialEnded,
                SubscriptionSubStatus.TrialExtended,
                SubscriptionSubStatus.TrialExtendedEnded,
                SubscriptionSubStatus.SubscriptionResumed,
            ],
        });

        this.statuses.push({
            status: SubscriptionStatus.Inactive,
            icon: "fal fa-xmark",
            text: "Inactive",
            validSubStatuses: [
                SubscriptionSubStatus.None,
                SubscriptionSubStatus.TrialEnded,
                SubscriptionSubStatus.TrialExtendedEnded,
                SubscriptionSubStatus.SubscriptionCancelled,
            ],
        });

        this.statuses.push({
            status: SubscriptionStatus.PendingInactive,
            icon: "fal fa-sack-xmark",
            text: "Pending Inactive",
            validSubStatuses: [
                SubscriptionSubStatus.SubscriptionCancelled,
            ],
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.updateOrganisationData();
        }
    }

    public get isAlto() {
        return !AdaptClientConfiguration.IsCurrentEmbedApiBaseUri;
    }

    public statusGroupClick({ itemData }: ItemClickEvent) {
        if (itemData.status === SubscriptionStatus.Active) {
            this.dialogService.open(StartSubscriptionDialogComponent).subscribe((date: Date) => {
                this.account!.status = SubscriptionStatus.Active;

                if (!this.account!.subscriptionCommencedDate) {
                    this.account!.subscriptionCommencedDate = date;
                }

                this.account!.nextSubscriptionInvoiceDate = date;
            });
        } else if (itemData.status === SubscriptionStatus.Inactive) {
            this.dialogService.openConfirmationDialogWithBoolean({
                title: "Change to inactive?",
                message: "We strongly recommend using the runADAPT tool 'Archive Organisation' instead of doing it this way.<br/><br/>"
                    + "The tool will make access readonly, remove the coach connection, and clean up the organisation properly.<br/><br/>"
                    + "Are you sure you want to do this here?",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).subscribe((result) => {
                if (result) {
                    this.account!.status = itemData.status;
                    this.account!.nextSubscriptionInvoiceDate = null;
                } else {
                    this.selectedItemStatus = [this.account!.status];
                }
            });
        } else if (itemData.status === SubscriptionStatus.Trial) {
            if (this.account!.status === SubscriptionStatus.Inactive) {
                this.account!.nextSubscriptionInvoiceDate = moment()
                    .startOf("day")
                    .add(2, "weeks").toDate();
                this.resumeTrial().subscribe();
            } else {
                this.dialogService.showMessageDialog("Status Change Error", "This status change is not supported - please see tech team if you want to do this.")
                    .subscribe();
                this.selectedItemStatus = [this.account!.status];
            }
        } else if (itemData.status === SubscriptionStatus.PendingInactive) {
            // don't clear the sub date
            this.account!.status = itemData.status;
        } else {
            this.account!.status = itemData.status;
            this.account!.nextSubscriptionInvoiceDate = null;
        }
    }

    private resumeTrial() {
        const dialogData = {
            title: "Resume trial",
            tooltip: "This will set the organisation status to trial, unarchive the organisation, and set the next charge date to the selected date",
            entities: [{
                entity: this.account!,
                fieldName: "nextSubscriptionInvoiceDate",
                label: "Trial end date (Next Subscription Charge Date)",
                type: SimpleValueType.Date,
            }],
            saveOnClose: false,
        } as IEditSimpleValueBreezeEntityDialogData;
        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, dialogData).pipe(
            switchMap(() => this.stakeholderServicesService.resumeTrial({ organisationId: this.organisation!.organisationId })),
            tap(() => {
                this.account!.status = SubscriptionStatus.Trial;
                this.account!.subStatus = SubscriptionSubStatus.TrialExtended;
            }),
            switchMap(() => this.savePaymentInternal()),
            switchMap(() => this.dialogService.showMessageDialog("Success!", "Organisation trial resumed")),
            catchError((e) => this.dialogService.showMessageDialog("Failed to resume trial", ErrorHandlingUtilities.getHttpResponseMessage(e))),
        );
    }

    private cancelAnyEdit() {
        if (this.editBillingDetailsMode) {
            this.cancelBillingDetails();
        }

        if (this.editPaymentMode) {
            this.cancelPayment();
        }
    }

    public editBillingDetails() {
        this.cancelAnyEdit();
        this.editBillingDetailsMode = true;
    }

    @Autobind
    public saveBillingDetails() {
        return this.organisationsService.save().pipe(
            tap(() => {
                this.stopEditingBillingDetails();
                this.updateOrganisationData();
            }),
            catchError((e: any) => this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    @Autobind
    public cancelBillingDetails() {
        return this.organisationsService.cancel().pipe(
            tap(() => this.stopEditingBillingDetails()),
        );
    }

    private stopEditingBillingDetails() {
        this.errorMessage = undefined;
        this.editBillingDetailsMode = false;
    }

    public editPayment() {
        this.cancelAnyEdit();
        this.editPaymentMode = true;
        this.selectedItemStatus = [this.account!.status];
    }

    @Autobind
    public savePayment() {
        return this.savePaymentInternal().pipe(
            catchError((e: any) => this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    private savePaymentInternal() {
        return this.organisationsService.save().pipe(
            tap(() => {
                this.stopEditingPayment();
                this.updateOrganisationData();
            }),
        );
    }

    @Autobind
    public cancelPayment() {
        return this.organisationsService.cancel().pipe(
            tap(() => this.stopEditingPayment()),
        );
    }

    public stopEditingPayment() {
        this.errorMessage = undefined;
        this.editPaymentMode = false;
    }

    @Autobind
    public removeDefaultCard() {
        const dialogData = {
            title: "Remove default card",
            message: `Are you sure you would like to remove the default card for <b>${this.organisation!.name}</b>? This cannot be easily undone!`,
        };

        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => this.stakeholderServicesService.detachPaymentMethod({ organisationId: this.organisation!.organisationId })),
            switchMap(() => this.dialogService.showMessageDialog("Remove default card", "The default card has been removed for this organisation.")),
            switchMap(() => this.updateOrganisationData()),
        );
    }

    @Autobind
    private async updateOrganisationData() {
        if (!this.organisation) {
            return;
        }

        // fetch the pricing models prior to anything else
        // (theres a possible race condition where the pricingModelType is set, but pricing models isn't, so the onChangedEvent handler falls over)
        // todo: move this to component init to remove this hack
        this.pricingModels = await lastValueFrom(this.organisationsService.getPricingModels());

        this.account = this.organisation.getAccount();
        this.pricingModelType = this.account!.pricingModelId
            ? PricingModel.PerUserPricingModelDescription
            : PricingModel.FixedPricingModelDescription;

        await this.paymentProcessingService.getInvoices(this.organisation.organisationId);

        if (this.account?.extensions.isBilledUsingCreditCard && this.account?.extensions.isCreditCardSet) {
            this.cardDetailsLoading = true;
            this.cardDetails = await lastValueFrom(this.paymentProcessingService.getCreditCardPartialDetails({ organisationId: this.organisation.organisationId }));
            this.cardDetailsLoading = false;
        }

        this.selectedItemStatus = [this.account!.status];
        this.selectedItemStatusObject = this.statuses.find((s) => s.status === this.account!.status);
    }

    public pricingModelTypeChanged(event: IDxSelectBoxValueChangedEvent<string>) {
        if (event.value === PricingModel.FixedPricingModelDescription) {
            this.account!.pricingModelId = null;
        } else if (event.value === PricingModel.PerUserPricingModelDescription) {
            const defaultPricingModel = this.pricingModels.find((i) => i.isDefault);
            if (!defaultPricingModel) {
                throw new Error("missing default pricing model");
            }

            this.account!.pricingModelId = defaultPricingModel.pricingModelId;
            this.account!.monthlyFeeDollars = 0;
            this.account!.billingPeriod = 1;
            this.account!.paymentMethod = PaymentMethod.CreditCard;
        } else {
            throw new Error("unknown pricing model type");
        }
    }
}
