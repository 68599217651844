import { Component, Inject } from "@angular/core";
import { Partner } from "@common/ADAPT.Common.Model/partner/partner";
import { DocumentationSubscriptionLevel, DocumentationSubscriptionLevelLabel } from "@common/ADAPT.Common.Model/person/documentation-subscription-level";
import { CoachTypeMetadata } from "@common/ADAPT.Common.Model/practitioner/coach-type";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-coach-details-dialog",
    templateUrl: "./coach-details-dialog.component.html",
})
export class CoachDetailsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Practitioner> {
    public readonly dialogName = "CoachDetails";
    protected readonly autoResolveData = this.practitioner;

    public isNew: boolean;
    public entitiesToConfirm: IBreezeEntity[];
    public allLevels = [
        DocumentationSubscriptionLevel.Anonymous,
        DocumentationSubscriptionLevel.Coaches,
        DocumentationSubscriptionLevel.OwnerDirectorCoaches,
    ];
    public allTypes = CoachTypeMetadata.All;
    public documentationSubscriptionLevelLabeller = DocumentationSubscriptionLevelLabel;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public practitioner: Practitioner,
    ) {
        super();

        this.isNew = practitioner.entityAspect.entityState.isAdded();

        this.entitiesToConfirm = [
            practitioner,
            practitioner.person.preferences,
        ];
    }

    public partnerSelectionChanged(partner: Partner) {
        this.practitioner.partner = partner;
    }
}
