import { Injectable } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Practitioner, PractitionerBreezeModel } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { INavigationHierarchy } from "@common/route/navigation-hierarchy";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { UserService } from "@common/user/user.service";
import { authAuditPageRoute } from "app/audit/auth-audit-page/auth-audit-page.route";
import { displayCoachListRoute } from "app/features/coaches/coach-list-page/coach-list-page-route";
import { displayCoachRoute } from "app/features/coaches/coach-page/coach-page-route";
import { organisationPageRoute } from "app/features/organisations/organisation-page/organisation-page.route";
import { organisationsDashboardPageRoute } from "app/features/organisations/organisations-dashboard-page/organisations-dashboard-page.route";
import { displayPartnerListRoute } from "app/features/partners/partner-list-page/partner-list-page-route";
import { PeopleService } from "app/features/people/people.service";
import { peopleDashboardPageRoute } from "app/features/people/people-dashboard-page/people-dashboard-page.route";
import { personPageRoute } from "app/features/people/person-page/person-page.route";
import { archiveOrganisationPageRoute } from "app/features/tools/archive-organisation-page/archive-organisation-page.route";
import { archiveValueStreamPageRoute } from "app/features/tools/archive-value-stream-page/archive-value-stream-page.route";
import { configureOrganisationFeaturesRoute } from "app/features/tools/configure-organisation-features/configure-organisation-features.route";
import { copyMeetingAgendaTemplatePageRoute } from "app/features/tools/copy-meeting-agenda-template-page/copy-meeting-agenda-template-page.route";
import { copySystemPageRoute } from "app/features/tools/copy-system-page/copy-system-page.route";
import { copyTeamPageRoute } from "app/features/tools/copy-team-page/copy-team-page.route";
import { deleteOrganisationPageRoute } from "app/features/tools/delete-organisation-page/delete-organisation-page.route";
import { deleteOrphanPeoplePageRoute } from "app/features/tools/delete-orphan-people-page/delete-orphan-people-page.route";
import { deleteOrphanUsersPageRoute } from "app/features/tools/delete-orphan-users-page/delete-orphan-users-page.route";
import { deleteSurveyPageRoute } from "app/features/tools/delete-survey-page/delete-survey-page.route";
import { deleteUserPageRoute } from "app/features/tools/delete-user-page/delete-user-page.route";
import { deleteValueStreamPageRoute } from "app/features/tools/delete-value-stream-page/delete-value-stream-page.route";
import { listPrivilegedUsersRoute } from "app/features/tools/list-privileged-users-page/list-privileged-users-page.route";
import { mergeUsersPageRoute } from "app/features/tools/merge-users-page/merge-users-page.route";
import { moveBoardPageRoute } from "app/features/tools/move-board-page/move-board-page.route";
import { rerankKanbanItemsRoute } from "app/features/tools/rerank-kanban-items/rerank-kanban-items.route";
import { seedTrainingOrganisationRoute } from "app/features/tools/seed-training-organisations/seed-training-organisations.route";
import { triggerStewardshipActivityRoute } from "app/features/tools/trigger-stewardship-activity/trigger-stewardship-activity.route";
import { triggerSurveySchedulerRoute } from "app/features/tools/trigger-survey-scheduler/trigger-survey-scheduler.route";
import { deepDiveListPageRoute } from "app/features/workflow/deep-dive/deep-dive-list-page/deep-dive-list-page.route";
import { NimbusDataService } from "app/services/nimbus-data.service";
import { EMPTY, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { pathwayReviewPageRoute } from "../audit/pathway-rating-audit/pathway-rating-audit.component";
import { cacheStatusPageRoute } from "../features/tools/cache-status-page/cache-status-page.route";
import { seedDatabasePageRoute } from "../features/tools/seed-database-page/seed-database-page.route";
import { triggerEventSchedulerRoute } from "../features/tools/trigger-event-scheduler/trigger-event-scheduler.route";
import { landingPageRoute } from "./landing-page/landing-page.route";

@Injectable()
export class NimbusNavigationService implements IDynamicNodeBuilder, INavigationHierarchy {
    public static readonly HierarchyId = "adapt.nimbus.navigation.hierarchy";

    public id = NimbusNavigationService.HierarchyId;
    public hierarchyNode$: Observable<INavigationNode | undefined>;
    public activeNodeShouldBeRebuilt$ = EMPTY;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private commonDataService: CommonDataService,
        private nimbusDataService: NimbusDataService,
        private peopleService: PeopleService,
        userService: UserService,
    ) {
        this.hierarchyNode$ = userService.currentPerson$.pipe(
            switchMap((person) => person ? this.promiseToUpdate() : of(undefined)),
        );
    }

    @Autobind
    public buildDynamicNodes() {
        return [
            this.buildDynamicOrganisationNode(),
            this.buildDynamicCoachNode(),
            this.buildDynamicPersonNode(),
        ];
    }

    public promiseToUpdate() {
        return this.navUtilitiesFactory.nodeBuilderForControllerAndParams(landingPageRoute.id)
            .setTitle("Home")
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(organisationsDashboardPageRoute.id)
            .promiseToAddChildController(displayCoachListRoute.id)
            .promiseToAddChildController(displayPartnerListRoute.id)
            .promiseToAddChildController(peopleDashboardPageRoute.id)
            .promiseToAddChild(this.promiseToGenerateToolsNode())
            .promiseToAddChild(this.promiseToGenerateTechTeamToolsNode())
            .promiseToBuild();
    }

    private promiseToGenerateToolsNode() {
        const builder = this.navUtilitiesFactory.nodeBuilder()
            .setTitle("Tools")
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(deleteOrganisationPageRoute.id)
            .promiseToAddChildController(mergeUsersPageRoute.id)
            .promiseToAddChildController(deleteUserPageRoute.id)
            .promiseToAddChildController(deleteValueStreamPageRoute.id)
            .promiseToAddChildController(deleteSurveyPageRoute.id)
            .promiseToAddChildController(archiveValueStreamPageRoute.id)
            .promiseToAddChildController(authAuditPageRoute.id)
            .promiseToAddChildController(moveBoardPageRoute.id)
            .promiseToAddChildController(copySystemPageRoute.id)
            .promiseToAddChildController(copyTeamPageRoute.id)
            .promiseToAddChildController(seedTrainingOrganisationRoute.id)
            .promiseToAddChildController(archiveOrganisationPageRoute.id)
            .promiseToAddChildController(copyMeetingAgendaTemplatePageRoute.id);

        // only add pathway rating tab for alto.
        if (!AdaptClientConfiguration.IsCurrentEmbedApiBaseUri) {
            builder.promiseToAddChildController(pathwayReviewPageRoute.id);
        }

        return builder.promiseToBuild();
    }

    private promiseToGenerateTechTeamToolsNode() {
        return this.navUtilitiesFactory.nodeBuilder()
            .setTitle("Tech Team Only")
            .setCustomKeyValue("onClickGoToController", seedDatabasePageRoute.id)
            .keepChildrenInAddedOrder()
            .promiseToAddChildController(seedDatabasePageRoute.id)
            .promiseToAddChildController(deleteOrphanUsersPageRoute.id)
            .promiseToAddChildController(deleteOrphanPeoplePageRoute.id)
            .promiseToAddChildController(cacheStatusPageRoute.id)
            .promiseToAddChildController(configureOrganisationFeaturesRoute.id)
            .promiseToAddChildController(rerankKanbanItemsRoute.id)
            .promiseToAddChildController(triggerSurveySchedulerRoute.id)
            .promiseToAddChildController(listPrivilegedUsersRoute.id)
            .promiseToAddChildController(triggerStewardshipActivityRoute.id)
            .promiseToAddChildController(triggerEventSchedulerRoute.id)
            .promiseToAddChildController(deepDiveListPageRoute.id)
            .promiseToBuild();
    }

    private buildDynamicOrganisationNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<Organisation>({
            entityIdParamName: "organisationUrlIdentifier",
            getEntityByParamCallback: (id) => this.nimbusDataService.getOrganisationByUrlIdentifier(id),
            getValue: (org) => org.name,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(organisationPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(organisationsDashboardPageRoute.id)
            .build();
    }

    private buildDynamicCoachNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<Practitioner>({
            entityIdParamName: "coachId",
            getEntityByParamCallback: (id) => this.commonDataService.getById(PractitionerBreezeModel, id),
            getValue: (coach) => coach.person.fullName,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(displayCoachRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(displayCoachListRoute.id)
            .build();
    }

    private buildDynamicPersonNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<Person>({
            entityIdParamName: "personId",
            getEntityByParamCallback: (id) => this.peopleService.getPersonById(id),
            getValue: (person) => person.fullName,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(personPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(peopleDashboardPageRoute.id)
            .build();
    }
}
